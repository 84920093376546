import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  IconButton,
} from "@mui/material";
import editprofile from "../../assets/Walnut/editprofil.png";
import api from "../../api/tokenCode";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./StoreManagement.css";
import { notifySuccess, notifyError } from "../../Constant/toast";
import CurrencyInput from 'react-currency-masked-input'


const EditProductModal = ({ open, handleClose, product }) => {
  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (product) {
      setProductName(product.productName || "");
      setPrice(product.price || "");
      setCategory(product.category || "");
      setDescription(product.Description || "");
      setImage(product.productPicter || null);
    }
  }, [product, open]);

  const handleSaveClick = async (event) => {
    event.preventDefault();

    if (isSaving) return;
    setIsSaving(true);

    const newErrors = {};
    if (!productName) newErrors.productName = "* Product name is required.";
    if (!price) newErrors.price = "* Price is required.";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsSaving(false);
      return;
    }

    const formData = new FormData();
    formData.append("productName", productName);
    formData.append("price", price);
    formData.append("category", category);
    formData.append("description", description);
    formData.append("productId", product._id);

    if (fileInputRef.current?.files[0]) {
      formData.append("file", fileInputRef.current.files[0]);
    } else if (image) {
      formData.append("productPicter", image);
    }

    try {
      const response = await api.put('/admin/updateProducts', formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      notifySuccess("Your product has been updated successfully!");
      handleClose();
      setTimeout(() => {
        navigate("/store");
      }, 1500);
    } catch (error) {
      console.error("Failed to update product:", error);
      notifyError("Failed to update product.");
    } finally {
      setIsSaving(false);
    }
  };

  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     setImage(URL.createObjectURL(file));
  //   }
  // };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFormats = [
        "image/png",
        "image/jpeg",
        "image/jpg",
        "image/tiff",
        "image/tif",
        "image/gif",
        "image/webp",
      ];
      if (validFormats.includes(file.type)) {
        setImage(URL.createObjectURL(file));
      } else {
        setErrors({
          image: "Unsupported file format. Please upload a valid image file.",
        });
        setImage(null);
      }
    }
  };
  

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setImage(null);
      setErrors({});
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="xl"
      sx={{
        marginLeft: "55px",
        "& .MuiDialog-paper": {
          overflowX: "hidden",
        },
      }}
 
      key={product ? product._id : "new"}
    >
      <DialogTitle>
        <div className="dialog-container">
          <div className="edit-product-section">
            {image ? (
              <img
                src={image}
                alt="Selected Product"
                className="add-image-tag"
                onClick={handleIconClick}

              />
            ) : (
              <img
                src={editprofile}
                alt="Profile"
                className="add-image-tag"
                onClick={handleIconClick}
              />
            )}

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
            />
            <div>
              <div
                className="product-heading"
              >
                <span
                className="product-headings"
                
                >{"Update product"}</span>
              </div>
              <div>
                {errors.image && ( 
                  <FormHelperText error sx={{ marginTop: "10px", textAlign: "center" }}>
                    {errors.image}
                  </FormHelperText>
                )}
              </div>
            </div>

          </div>

          <div className="modal-button">
            <Button sx={{  backgroundColor: "white",
              color: "black",
              padding: "8px 8px",
              fontSize: "12px",
              border: "1px solid black",
              width: "120px",
              textAlign: "center",}} 
              onClick={handleDialogClose}>
              Cancel
            </Button>

            <Button
               sx={{  backgroundColor: "black",
                color: "white",
                padding: "8px 8px",
                fontSize: "12px",
                border: "1px solid white",
                width: "120px",
                textAlign: "center",}}
              onClick={handleSaveClick}
              disabled={isSaving}
            >
              {isSaving ? "Updating..." : "Update"}
            </Button>
          </div>
        </div>

        <div className="modal-input">
          <div className="modal-div">
            <label className="modal-lable">Product name:</label>
            <input
              placeholder="Enter the product name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === " " && productName.length === 0) {
                  e.preventDefault(); 
                }
              }}
              className="modal-inputfield"
            />
            {errors.productName && (
              <FormHelperText error>{errors.productName}</FormHelperText>
            )}
          </div>

          <div className="modal-div">
            <label className="modal-lable">Price:</label>
            <CurrencyInput
                type="text"
                inputMode="numeric"
                pattern="\d*(\.\d{2})?"
                value={price !== null && !isNaN(price) ? `$${(+price).toFixed(2)}` : "$ 0.00"}
                name="price-input"
                placeholder="$0.00"
                onChange={(e, maskedVal) => {
                  const numericValue = maskedVal.replace(/[^0-9.]/g, "");
                  setPrice(numericValue ? +numericValue : null);
                }}
                className="currency-field"

              />
            {errors.price && <FormHelperText error>{errors.price}</FormHelperText>}
          </div>

        </div>

        <div className="modal-category">
          <div className="modal-categorys">
            <label  className="category-lable">Category:</label>
            <RadioGroup
              row
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <FormControlLabel
                value="Snacks"
                control={<Radio />}
                label="Snacks/Drinks"
              />
              <FormControlLabel
                value="Gears"
                control={<Radio />}
                label="Gears"
              />
            </RadioGroup>
            {errors.category && (
              <FormHelperText error>{errors.category}</FormHelperText>
            )}
          </div>
        </div>

        <div  className="category-div">
          <div className="modal-categorys" >
            <label className="category-lable">Description (optional):</label>
            <input
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="description-field"
            />
          </div>
        </div>

        {errors.image && (
          <FormHelperText error>{errors.image}</FormHelperText>
        )}
      </DialogTitle>
    </Dialog>
  );
};

export default EditProductModal;
