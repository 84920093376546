import React,{useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


function DeleteUser() {
 const navigate = useNavigate();


 const handleDelete = () => {
   const confirmDelete = window.confirm('Are you sure you want to delete your account?');


   if (confirmDelete) {
     // Perform delete logic here
     console.log('User deleted'); // Replace with actual delete logic


     // Redirect to the login page
     navigate('/');
   }
 };


 return (
   <div
     style={{
       display: 'flex',
       flexDirection: 'column',
       justifyContent: 'center',
       alignItems: 'center',
       height: '100vh',
       textAlign: 'center',
     }}
   >
     {/* Profile Icon */}
     <Avatar
       style={{
         width: '100px',
         height: '100px',
         backgroundColor: '#f0f0f0',
         marginBottom: '20px',
         boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
       }}
     >
       <AccountCircleIcon style={{ fontSize: '60px', color: '#555' }} />
     </Avatar>


     <h2>Delete User</h2>
     <p>Click the button below to confirm account deletion.</p>
     <button
       onClick={handleDelete}
       style={{
         backgroundColor: '#000000',
         color: 'white',
         border: 'none',
         padding: '10px 20px',
         borderRadius: '5px',
         cursor: 'pointer',
         fontSize: '16px',
         marginTop: '10px',
         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
       }}
     >
       Delete User
     </button>
   </div>
 );
}


export default DeleteUser;