import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./TopNavbar.css";
import avatarIcon from "../../assets/Walnut/avator.png";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import NotificationsSharpIcon from "@mui/icons-material/NotificationsSharp";
import inviteIcon from "../../assets/Walnut/invite-user.svg";
import CreateUser from "../CreateUser/CreateUser";

const TopNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleLogout = () => {
    setShowDropdown(false);
    localStorage.removeItem("authToken");
    localStorage.setItem("logoutEvent", Date.now());
    navigate("/");
  };

  const handleInviteClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="top-nav">
      <ul className="user-info" ref={dropdownRef}>
        <img
          src={inviteIcon}
          alt="invite"
          onClick={handleInviteClick}
          className="avatar"
        />
        <NotificationsSharpIcon sx={{ color: "gray", marginRight: "25px" }} />
        <div className="vl"></div>

        <img src={avatarIcon} alt="Avatar" className="avatar" />

        <button
          className="dropdown-btn"
          onClick={toggleDropdown}
        >
          <KeyboardArrowDownSharpIcon sx={{ color: "gray" }} />
        </button>

        {showDropdown && (
          <ul
            className="dropdown-menu"
          >
            <div className="logout">
              <span onClick={handleLogout}>Logout</span>
            </div>
          </ul>
        )}
      </ul>

      <CreateUser
        open={open}
        handleClose={handleClose}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default TopNavbar;