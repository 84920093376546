// src/utils/toastUtils.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Success toast notification
export const notifySuccess = (message) => {
  toast.dismiss();
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    className: 'toast-success',
    bodyClassName: 'toast-body',
    progressClassName: 'toast-progress',
    theme:"colored"
  });
};

// Error toast notification
// export const notifyError = (message) => {
//   toast.dismiss();
//   toast.error(message, {
//     position: "top-right",
//     autoClose: 3000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     className: 'toast-error',
//     bodyClassName: 'toast-body',
//     progressClassName: 'toast-progress',
//     theme:"colored",
//     Zindex:1500
//   });
// };


export const notifyError = (message) => {
    toast.dismiss();
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      className: 'toast-error',
      bodyClassName: 'toast-body',
      progressClassName: 'toast-progress',
      theme: "colored",
    
    });
  };
  


// Info toast notification
export const notifyInfo = (message) => {
  toast.dismiss();
  toast.info(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme:"colored"
  });
};
