
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MemberManagement from '../components/MemberManagement/MemberManagement';
import NotificationManagement from '../components/NotificationManagemant/NotificationManagement';
import SchedulingManagement from '../components/SchedulingManagemant/SchedulingManagement';
import StoreManagement from '../components/StoreManagement/StoreManagement';
import Login from '../components/Login/Login';
import CreateUser from '../components/CreateUser/CreateUser';
import DeleteUser from '../components/DeleteUser/DeleteUser';


function Main() {
 return (
   <div>
     <Routes>
       <Route path="/" element={<Login />} />
       <Route path="/member" element={<MemberManagement />} />
       <Route path="/notification" element={<NotificationManagement />} />
       <Route path="/scheduling" element={<SchedulingManagement />} />
       <Route path="/store" element={<StoreManagement />} />
       <Route path="/CreateUser" element={<CreateUser />} />
       <Route path="/deleteUser" element={<DeleteUser />} />
     </Routes>
   </div>
 );
}


export default Main;


