// import React, { useState, useEffect } from "react";
// import {
//   Box,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Switch,
//   CircularProgress,
// } from "@mui/material";
// import { Calendar, momentLocalizer } from "react-big-calendar";
// import moment from "moment";
// import api from "../../api/tokenCode";
// import LeftSidenav from "../LeftSidenav/LeftSidenav";
// import TopNavbar from "../TopNavbar/TopNavbar";
// import "react-big-calendar/lib/css/react-big-calendar.css";
// import ExportButton from "./ExportButton";
// import "./SchedulingManagement.css";

// const ScheduleManagement = () => {
//   const [calendarView, setCalendarView] = useState(false);
//   const [bookings, setBookings] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [filter, setFilter] = useState({
//     search: "",
//     status: "",
//     simulatorBay: "",
//   });

//   const [viewDates, setViewDates] = useState({
//     startDate: moment().startOf("month").format("YYYY-MM-DD"),
//     endDate: moment().endOf("month").format("YYYY-MM-DD"),
//   });

//   const [currentDate, setCurrentDate] = useState(new Date());
//   const [currentView, setCurrentView] = useState("month");

//   const localizer = momentLocalizer(moment);

//   const fetchBookings = async (startDate, endDate) => {
//     setLoading(true);
//     try {
//       const response = await api.get("/booking/getBookingsforAdmin", {
//         params: { startDate, endDate },
//       });
//       setBookings(response.data.list || []);
//     } catch (err) {
//       setError("Error fetching bookings");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Fetch bookings on initial load for the current month
//   useEffect(() => {
//     fetchBookings(
//       moment(currentDate).startOf("month").format("YYYY-MM-DD"),
//       moment(currentDate).endOf("month").format("YYYY-MM-DD")
//     );
//   }, [currentDate]);

//   const handleFilterChange = (event) => {
//     const { name, value } = event.target;
//     setFilter({ ...filter, [name]: value });
//   };

//   const filteredSchedules =
//     bookings.filter((row) => {
//       const fullName = `${row.firstName} ${row.lastName}`;
//       return (
//         fullName.toLowerCase().includes(filter.search.toLowerCase()) &&
//         (filter.simulatorBay ? row.simulatorBay === filter.simulatorBay : true)
//       );
//     }) || [];

//     const handleCalendarViewToggle = (event) => {
//       const isCalendarView = event.target.checked;
//       setCalendarView(isCalendarView);
    
//       if (!isCalendarView) {
//         const startDate = moment(currentDate).startOf("month").format("YYYY-MM-DD");
//         const endDate = moment(currentDate).endOf("month").format("YYYY-MM-DD");
//         setViewDates({ startDate, endDate });
//         fetchBookings(startDate, endDate);
//       }
//     };
    
//     const handleNavigate = (newDate) => {
//       setCurrentDate(newDate); 
    
//       if (currentView === "month") {
//         const startDate = moment(newDate).startOf("month").format("YYYY-MM-DD");
//         const endDate = moment(newDate).endOf("month").format("YYYY-MM-DD");
//         setViewDates({ startDate, endDate });
//         fetchBookings(startDate, endDate);
//       } else if (currentView === "week") {
//         const startDate = moment(newDate).startOf("week").format("YYYY-MM-DD");
//         const endDate = moment(newDate).endOf("week").format("YYYY-MM-DD");
//         fetchBookings(startDate, endDate);
//       } else if (currentView === "day") {
//         const startDate = moment(newDate).format("YYYY-MM-DD");
//         const endDate = moment(newDate).format("YYYY-MM-DD");
//         fetchBookings(startDate, endDate);
//       }
//     };
    
//     const handleViewChange = (view) => {
//       setCurrentView(view); 
    
//       if (view === "month") {
//         const startDate = moment(currentDate).startOf("month").format("YYYY-MM-DD");
//         const endDate = moment(currentDate).endOf("month").format("YYYY-MM-DD");
//         setViewDates({ startDate, endDate });
//         fetchBookings(startDate, endDate);
//       } else if (view === "week") {
//         const startDate = moment(currentDate).startOf("week").format("YYYY-MM-DD");
//         const endDate = moment(currentDate).endOf("week").format("YYYY-MM-DD");
//         fetchBookings(startDate, endDate);
//       } else if (view === "day") {
//         const startDate = moment(currentDate).format("YYYY-MM-DD");
//         const endDate = moment(currentDate).format("YYYY-MM-DD");
//         fetchBookings(startDate, endDate);
//       }
//     };
    
//     useEffect(() => {
//       const startDate = moment(currentDate).startOf("month").format("YYYY-MM-DD");
//       const endDate = moment(currentDate).endOf("month").format("YYYY-MM-DD");
//       setViewDates({ startDate, endDate });
//       fetchBookings(startDate, endDate);
//     }, [currentDate]);
    

//   const tableHeaderCellStyle = {
//     color: "#000",
//     fontWeight: "bold",
//     padding: "20px 15px",
//     border: "1px solid #ccc",
//     backgroundColor: "#F6F8FA",
//     textAlign: "center",
//     fontSize: "18px",
//   };

//   const tableCellStyle = {
//     padding: "10px 15px",
//     border: "1px solid #ccc",
//     backgroundColor: "#fff",
//     textAlign: "center",
//     fontSize: "15px",
//   };

//   return (
//     <Box sx={{ marginLeft: 5 }}>
//       <LeftSidenav />
//       <TopNavbar />

//       <Box className="padding-container">
//         <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
//           <Table>
//             <div className="header">
//               <h2 className="header-name">Schedule Management</h2>
//               <div
//                 className="schedule-toggle"

//               >
//                 <span className="schedule-switch" >Calendar View</span>
//                 <Switch
//                   checked={calendarView}
//                   onChange={handleCalendarViewToggle}
//                   className="schedule-switch"
//                 />
//                 <ExportButton />
//               </div>
//             </div>
//           </Table>
//           <hr />

//           <div id="export">
//             <Table className="schedule-table">
//               <div className="schedul-tablediv" >
//                 <div className="schedule-filters">
//                   {!calendarView && (
//                     <input
//                       name="search"
//                       className="schedule-search-text"
//                       placeholder="Search by name"
//                       value={filter.search}
//                       onChange={handleFilterChange}
//                       variant="outlined"
//                       size="small"
//                       sx={{ marginRight: 1 }}
//                     />
//                   )}
//                 </div>
//               </div>
//             </Table>

//             {loading ? (
//               <div className="schedule-loading" >
//                 <CircularProgress />
//               </div>
//             ) : error ? (
//               <div
//                 className="schedule-error"
//               >
//                 {error}
//               </div>
//             ) : calendarView ? (
//               <div className="calendar-container">
//                 {/* <Calendar
//                   localizer={localizer}
//                   events={bookings.map((row) => ({
//                     title: `${row.firstName} ${row.lastName}`,
//                     start: new Date(row.startTime),
//                     end: new Date(row.endTime),
//                     bayNumber: row.bayNumber || "N/A",
//                   }))}
//                   startAccessor="start"
//                   endAccessor="end"
//                   date={currentDate}
//                   view={currentView}
//                   onNavigate={handleNavigate}
//                   onView={handleViewChange}
//                   className="schedule-calender"
//                   titleAccessor="title"
//                   views={["month", "week", "day"]}
//                   defaultView="month"
//                   components={{
//                     month: {
//                       event: ({ event }) => (
//                         <span>
//                           {new Date(event.start).toLocaleTimeString([], {
//                             hour: "2-digit",
//                             minute: "2-digit",
//                           })}{" "}
//                           -
//                           {new Date(event.end).toLocaleTimeString([], {
//                             hour: "2-digit",
//                             minute: "2-digit",
//                           })}
//                           <br />
//                           {event.title}
//                           <br />
//                           Bay: {event.bayNumber}
//                         </span>
//                       ),
//                     },
//                     week: {
//                       event: ({ event }) => (
//                         <span className="schedule-event">
//                           <div className="schedule-eventtitle">  {event.title}</div>
//                           <div >
//                             Bay: {event.bayNumber}
//                           </div>
//                         </span>
//                       ),
//                     },
//                     day: {
//                       event: ({ event }) => (
//                         <span className="schedule-event">
//                           <div className="schedule-eventtitle">
//                             {event.title}
//                           </div>
//                           <div>Bay: {event.bayNumber}</div>
//                         </span>
//                       ),
//                     },
//                   }}
//                 /> */}

// <Calendar
//                   localizer={localizer}
//                   events={bookings.map((row) => ({
//                     title: `${row.firstName} ${row.lastName}`,
//                     start: new Date(row.startTime),
//                     end: new Date(row.endTime),
//                     bayNumber: row.bayNumber || "N/A",
//                   }))}
//                   startAccessor="start"
//                   endAccessor="end"
//                   date={currentDate}
//                   view={currentView}
//                   onNavigate={handleNavigate}
//                   onView={handleViewChange}
//                   className="schedule-calender"
//                   titleAccessor="title"
//                   views={["month", "week", "day"]}
//                   defaultView="month"
//                   components={{
//                     month: {
//                       event: ({ event }) => (
//                         <div className="schedule-event" style={{ position: 'relative', zIndex: 1 }}>
//                           <span>
//                             {new Date(event.start).toLocaleTimeString([], {
//                               hour: "2-digit",
//                               minute: "2-digit",
//                             })}{" "}
//                             -
//                             {new Date(event.end).toLocaleTimeString([], {
//                               hour: "2-digit",
//                               minute: "2-digit",
//                             })}
//                             <br />
//                             {event.title}
//                             <br />
//                             Bay: {event.bayNumber}
//                           </span>
//                         </div>
//                       ),
//                     },
//                     week: {
//                       event: ({ event }) => (
//                         <div className="schedule-event" style={{ position: 'relative', zIndex: 1 }}>
//                           <div className="schedule-eventtitle">{event.title}</div>
//                           <div>Bay: {event.bayNumber}</div>
//                         </div>
//                       ),
//                     },
//                     day: {
//                       event: ({ event }) => (
//                         <div className="schedule-event" style={{ position: 'relative', zIndex: 1 }}>
//                           <div className="schedule-eventtitle">{event.title}</div>
//                           <div>Bay: {event.bayNumber}</div>
//                         </div>
//                       ),
//                     },
//                   }}

//                 />

//                 {loading && <p>Loading bookings...</p>}
//                 {error && <p classNam="schedule-paragraph" >{error}</p>}
//               </div>
//             ) : (
//               <TableContainer
//                 component={Paper}
//                 sx={{ backgroundColor: "#F6F8FA" }}
//               >
//                 <Table
//                   sx={{
//                     minWidth: 650,
//                     borderCollapse: "separate",
//                     borderSpacing: "0 20px",
//                   }}
//                 >
//                   <TableHead>
//                     <TableRow>
//                       <TableCell sx={tableHeaderCellStyle}>Name</TableCell>
//                       <TableCell sx={tableHeaderCellStyle}>
//                         Booking Date
//                       </TableCell>
//                       <TableCell sx={tableHeaderCellStyle}>Time Slot</TableCell>
//                       <TableCell sx={tableHeaderCellStyle}>
//                         Simulator Bay
//                       </TableCell>
//                     </TableRow>
//                   </TableHead>

//                   <TableBody>
//                     {filteredSchedules.length > 0 ? (
//                       filteredSchedules.map((schedule) => (
//                         <TableRow key={schedule._id}>
//                           <TableCell sx={tableCellStyle}>
//                             {`${schedule.firstName} ${schedule.lastName}`}
//                           </TableCell>
//                           <TableCell sx={tableCellStyle}>
//                             {moment(schedule.startTime).format("MM/DD/YYYY")}
//                           </TableCell>
//                           <TableCell sx={tableCellStyle}>
//                             {moment(schedule.startTime).format(" HH:mm -")}
//                             {moment(schedule.endTime).format(" HH:mm")}
//                           </TableCell>
//                           <TableCell sx={tableCellStyle}>
//                             <span>Bay- {schedule.bayNumber || "N/A"}</span>
//                           </TableCell>
//                         </TableRow>
//                       ))
//                     ) : (
//                       <TableRow>
//                         <TableCell
//                           colSpan={4}
//                           sx={{
//                             textAlign: "center",
//                             padding: "20px",
//                             fontSize: "20px",
//                             fontWeight: "bold",
//                           }}
//                         >
//                           No schedules found
//                         </TableCell>
//                       </TableRow>
//                     )}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             )}
//           </div>
//         </TableContainer>
//       </Box>
//     </Box>
//   );
// };

// export default ScheduleManagement;




import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
  CircularProgress,
} from "@mui/material";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import api from "../../api/tokenCode";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ExportButton from "./ExportButton";
import "./SchedulingManagement.css";

const ScheduleManagement = () => {
  const [calendarView, setCalendarView] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    simulatorBay: "",
  });

  const [viewDates, setViewDates] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD"),
    endDate: moment().endOf("month").format("YYYY-MM-DD"),
  });

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState("month");

  const localizer = momentLocalizer(moment);

  const fetchBookings = async (startDate, endDate) => {
    setLoading(true);
    try {
      const response = await api.get("/booking/getBookingsforAdmin", {
        params: { startDate, endDate },
      });
      setBookings(response.data.list || []);
    } catch (err) {
      setError("Error fetching bookings");
    } finally {
      setLoading(false);
    }
  };

  // Fetch bookings on initial load for the current month
  useEffect(() => {
    fetchBookings(
      moment(currentDate).startOf("month").format("YYYY-MM-DD"),
      moment(currentDate).endOf("month").format("YYYY-MM-DD")
    );
  }, [currentDate]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  };

  const filteredSchedules =
    bookings.filter((row) => {
      const fullName = `${row.firstName} ${row.lastName}`;
      return (
        fullName.toLowerCase().includes(filter.search.toLowerCase()) &&
        (filter.simulatorBay ? row.simulatorBay === filter.simulatorBay : true)
      );
    }) || [];

    const handleCalendarViewToggle = (event) => {
      const isCalendarView = event.target.checked;
      setCalendarView(isCalendarView);
    
      if (!isCalendarView) {
        const startDate = moment(currentDate).startOf("month").format("YYYY-MM-DD");
        const endDate = moment(currentDate).endOf("month").format("YYYY-MM-DD");
        setViewDates({ startDate, endDate });
        fetchBookings(startDate, endDate);
      }
    };
    
    const handleNavigate = (newDate) => {
      setCurrentDate(newDate); 
    
      if (currentView === "month") {
        const startDate = moment(newDate).startOf("month").format("YYYY-MM-DD");
        const endDate = moment(newDate).endOf("month").format("YYYY-MM-DD");
        setViewDates({ startDate, endDate });
        fetchBookings(startDate, endDate);
      } else if (currentView === "week") {
        const startDate = moment(newDate).startOf("week").format("YYYY-MM-DD");
        const endDate = moment(newDate).endOf("week").format("YYYY-MM-DD");
        fetchBookings(startDate, endDate);
      } else if (currentView === "day") {
        const startDate = moment(newDate).format("YYYY-MM-DD");
        const endDate = moment(newDate).format("YYYY-MM-DD");
        fetchBookings(startDate, endDate);
      }
    };
    
    const handleViewChange = (view) => {
      setCurrentView(view); 
    
      if (view === "month") {
        const startDate = moment(currentDate).startOf("month").format("YYYY-MM-DD");
        const endDate = moment(currentDate).endOf("month").format("YYYY-MM-DD");
        setViewDates({ startDate, endDate });
        fetchBookings(startDate, endDate);
      } else if (view === "week") {
        const startDate = moment(currentDate).startOf("week").format("YYYY-MM-DD");
        const endDate = moment(currentDate).endOf("week").format("YYYY-MM-DD");
        fetchBookings(startDate, endDate);
      } else if (view === "day") {
        const startDate = moment(currentDate).format("YYYY-MM-DD");
        const endDate = moment(currentDate).format("YYYY-MM-DD");
        fetchBookings(startDate, endDate);
      }
    };
    
    useEffect(() => {
      const startDate = moment(currentDate).startOf("month").format("YYYY-MM-DD");
      const endDate = moment(currentDate).endOf("month").format("YYYY-MM-DD");
      setViewDates({ startDate, endDate });
      fetchBookings(startDate, endDate);
    }, [currentDate]);
    

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize: "15px",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />

      <Box className="padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="header">
              <h2 className="header-name">Schedule Management</h2>
              <div
                className="schedule-toggle"

              >
                <span className="schedule-switch" >Calendar View</span>
                <Switch
                  checked={calendarView}
                  onChange={handleCalendarViewToggle}
                  className="schedule-switch"
                />
                <ExportButton />
              </div>
            </div>
          </Table>
          <hr />

          <div id="export">
            <Table className="schedule-table">
              <div className="schedul-tablediv" >
                <div className="schedule-filters">
                  {!calendarView && (
                    <input
                      name="search"
                      className="schedule-search-text"
                      placeholder="Search by name"
                      value={filter.search}
                      onChange={handleFilterChange}
                      variant="outlined"
                      size="small"
                      sx={{ marginRight: 1 }}
                    />
                  )}
                </div>
              </div>
            </Table>

            {loading ? (
              <div className="schedule-loading" >
                <CircularProgress />
              </div>
            ) : error ? (
              <div
                className="schedule-error"
              >
                {error}
              </div>
            ) : calendarView ? (
              <div className="calendar-container">
                {/* <Calendar
                  localizer={localizer}
                  events={bookings.map((row) => ({
                    title: `${row.firstName} ${row.lastName}`,
                    start: new Date(row.startTime),
                    end: new Date(row.endTime),
                    bayNumber: row.bayNumber || "N/A",
                  }))}
                  startAccessor="start"
                  endAccessor="end"
                  date={currentDate}
                  view={currentView}
                  onNavigate={handleNavigate}
                  onView={handleViewChange}
                  className="schedule-calender"
                  titleAccessor="title"
                  views={["month", "week", "day"]}
                  defaultView="month"
                  components={{
                    month: {
                      event: ({ event }) => (
                        <span>
                          {new Date(event.start).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}{" "}
                          -
                          {new Date(event.end).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                          <br />
                          {event.title}
                          <br />
                          Bay: {event.bayNumber}
                        </span>
                      ),
                    },
                    week: {
                      event: ({ event }) => (
                        <span className="schedule-event">
                          <div className="schedule-eventtitle">  {event.title}</div>
                          <div >
                            Bay: {event.bayNumber}
                          </div>
                        </span>
                      ),
                    },
                    day: {
                      event: ({ event }) => (
                        <span className="schedule-event">
                          <div className="schedule-eventtitle">
                            {event.title}
                          </div>
                          <div>Bay: {event.bayNumber}</div>
                        </span>
                      ),
                    },
                  }}
                /> */}

<Calendar
                  localizer={localizer}
                  events={bookings.map((row) => ({
                    title: `${row.firstName} ${row.lastName}`,
                    start: new Date(row.startTime),
                    end: new Date(row.endTime),
                    bayNumber: row.bayNumber || "N/A",
                  }))}
                  startAccessor="start"
                  endAccessor="end"
                  date={currentDate}
                  view={currentView}
                  onNavigate={handleNavigate}
                  onView={handleViewChange}
                  className="schedule-calender"
                  titleAccessor="title"
                  views={["month", "week", "day"]}
                  defaultView="month"
                  components={{
                    month: {
                      event: ({ event }) => (
                        <div className="schedule-event" style={{ position: 'relative', zIndex: 1 }}>
                          <span>
                            {new Date(event.start).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}{" "}
                            -
                            {new Date(event.end).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                            <br />
                            {event.title}
                            <br />
                            Bay: {event.bayNumber}
                          </span>
                        </div>
                      ),
                    },
                    week: {
                      event: ({ event }) => (
                        <div className="schedule-event" style={{ position: 'relative', zIndex: 1 }}>
                          <div className="schedule-eventtitle">{event.title}</div>
                          <div>Bay: {event.bayNumber}</div>
                        </div>
                      ),
                    },
                    day: {
                      event: ({ event }) => (
                        <div className="schedule-event" style={{ position: 'relative', zIndex: 1 }}>
                          <div className="schedule-eventtitle">{event.title}</div>
                          <div>Bay: {event.bayNumber}</div>
                        </div>
                      ),
                    },
                  }}

                />

                {loading && <p>Loading bookings...</p>}
                {error && <p classNam="schedule-paragraph" >{error}</p>}
              </div>
            ) : (
              <TableContainer
                component={Paper}
                sx={{ backgroundColor: "#F6F8FA" }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    borderCollapse: "separate",
                    borderSpacing: "0 20px",
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell sx={tableHeaderCellStyle}>Name</TableCell>
                      <TableCell sx={tableHeaderCellStyle}>
                        Booking Date
                      </TableCell>
                      <TableCell sx={tableHeaderCellStyle}>Time Slot</TableCell>
                      <TableCell sx={tableHeaderCellStyle}>
                        Simulator Bay
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredSchedules.length > 0 ? (
                      filteredSchedules.map((schedule) => (
                        <TableRow key={schedule._id}>
                          <TableCell sx={tableCellStyle}>
                            {`${schedule.firstName} ${schedule.lastName}`}
                          </TableCell>
                          <TableCell sx={tableCellStyle}>
                            {moment(schedule.startTime).format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell sx={tableCellStyle}>
                            {moment(schedule.startTime).format(" HH:mm -")}
                            {moment(schedule.endTime).format(" HH:mm")}
                          </TableCell>
                          <TableCell sx={tableCellStyle}>
                            <span>Bay- {schedule.bayNumber || "N/A"}</span>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={4}
                          sx={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          No schedules found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </div>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ScheduleManagement;
