import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import api from "../../api/tokenCode";
import "./MemberManagement.css";
import JoinDate from "../../assets/Walnut/joindate.svg";
import plan from "../../assets/Walnut/plan.svg";
import status from "../../assets/Walnut/status.svg";
import Frame from "../../assets/Walnut/Frame.svg";
import email from "../../assets/Walnut/email.svg";
import LeftSidenav from "../LeftSidenav/LeftSidenav";
import TopNavbar from "../TopNavbar/TopNavbar";
import CloseIcon from "@mui/icons-material/Close";
import { notifySuccess, notifyError } from "../../Constant/toast";
import "react-toastify/dist/ReactToastify.css";




const getInitials = (name) => {
  const names = name.split(" ");
  return names.length > 1
    ? names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase()
    : names[0].charAt(0).toUpperCase();
};

const MemberManagement = () => {
  const [members, setMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [filter, setFilter] = useState({
    search: "",
    status: "",
    plan: "",
    joinDate: "",
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [note, setNote] = useState("");
  const [currentMemberForNote, setCurrentMemberForNote] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fetchSubscribedUsers = async () => {
      try {
        const skip = page * rowsPerPage; 
        const limit = rowsPerPage;
        const response = await api.get("/admin/getSubscribedUsers", {
          params: {
            search: filter.search, 
            limit: limit,
            skip: skip,
          },
        });
        const data = response.data.list;
        setMembers(data); 
        setTotalCount(response.data.count); 
      } catch (error) {
        console.error("Error fetching subscribed users:", error);
      }
    };
  
    fetchSubscribedUsers();
  }, [page, rowsPerPage, filter.search]); 
  
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
  
    setPage(0);
  };
  

  const handleEditMember = (member, e) => {
    if (e && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    setSelectedMember(member);
  };

  const handleCloseDetails = (e) => {
    if (e && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    setSelectedMember(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleCloseNoteModal = () => {
    setOpenNoteModal(false);
    setNote("");
  };


  const handleOpenNoteModal = (member) => {
    setCurrentMemberForNote(member);
    setNote(member.note || "");
    setOpenNoteModal(true);
  };

  const handleSaveNote = async () => {
    try {
      const payload = {
        userId: currentMemberForNote._id,
        note: note,
      };

      const response = await api.put("/admin/addNote", payload);

      const updatedNote = response.data.UserDetails.note;

      if (response.status === 200) {
        console.log("Note saved successfully:", response.data);

        const updatedMembers = members.map((member) =>
          member._id === currentMemberForNote._id
            ? { ...member, note: updatedNote }
            : member
        );
        setMembers(updatedMembers);

        if (!currentMemberForNote.note || currentMemberForNote.note.trim() === "") {
          notifySuccess("Notes added successfully!");
        } else {
          notifySuccess("Notes updated successfully!");
        }

        setNote(updatedNote);

        setOpenNoteModal(false);
        setNote("");
      } else {
        console.error("Failed to save note:", response.data.message);
      }
    } catch (error) {
      console.error("Error saving note:", error.message);
    }
  };

  const ProfileImage = ({ name }) => {
    const initials = getInitials(name);

    return <div className="profile-image">{initials}</div>;
  };

  const tableHeaderCellStyle = {
    color: "#000",
    fontWeight: "bold",
    padding: "20px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#F6F8FA",
    textAlign: "center",
    fontSize: "18px",
  };

  const tableCellStyle = {
    padding: "10px 15px",
    border: "1px solid #ccc",
    backgroundColor: "#fff",
    textAlign: "center",
    fontSize: "15px",
    cursor: "pointer",
  };

  return (
    <Box sx={{ marginLeft: 5 }}>
      <LeftSidenav />
      <TopNavbar />

      <Box className="padding-container">
        <TableContainer component={Paper} sx={{ backgroundColor: "#F6F8FA" }}>
          <Table>
            <div className="header">
              <h2 className="header-name">Member Management</h2>
            </div>
          </Table>
          <hr />
          <Table>
            <div className="member-table" >
              <div
                className="filters"
              >
                <input
                  name="search"
                  className="search-textj"
                  placeholder="Search by name & email "
                  value={filter.search}
                  onChange={handleFilterChange}
                  variant="outlined"
                  size="small"
                />
              </div>
            </div>
          </Table>

          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0 20px",
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>First Name</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Last Name</TableCell>

                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>
                  Email Address
                </TableCell>

                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Join Date</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Subscription Plan </TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Status</TableCell>
                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>User Type</TableCell>

                <TableCell sx={{ ...tableHeaderCellStyle, width: "200px" }}>Note</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {members.length > 0 ? (
                members.map((member) => (
                  <React.Fragment key={member._id}>
                    <TableRow onClick={(e) => handleEditMember(member, e)}>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }} className="capitalize">
                        {member.firstName}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }} className="capitalize">
                        {member.lastName}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }}>{member.email}</TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }}>
                        {new Date(member.createdAt).toLocaleDateString("en-US").replace(/\//g, "-")}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }} className="capitalize">
                        {member.SubscriptionPlan.replace(/([a-zA-Z])([0-9])/g, "$1 $2")
                          .replace(/([0-9])([a-zA-Z])/g, "$1 $2")
                          .replace(/([a-zA-Z])([A-Z])/g, "$1 $2")
                          .replace(/_/g, "-")
                          .replace(/-/g, " - ")}
                      </TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }}>{member.status}</TableCell>
                      <TableCell sx={{ ...tableCellStyle, width: "200px" }}>{member.role}</TableCell>

                      <TableCell sx={{ ...tableCellStyle, width: "200px" }} onClick={(e) => {
                        e.stopPropagation();
                        handleOpenNoteModal(member);
                      }}>
                        <img
                          src={Frame}
                          alt="Frame"
                          className="frame-image"
                        />
                      </TableCell>
                    </TableRow>

                    {selectedMember && selectedMember._id === member._id && (
                      <TableRow>
                        <TableCell colSpan={8} sx={{ backgroundColor: "#EEF0F1", padding: "20px" }}>
                          <div className="member-details">

                            <div className="member-iconsection">
                              <CloseIcon
                                onClick={(e) => handleCloseDetails(e)}
                                className="member-closeicon"
                              />
                            </div>

                            <div className="selectmemSection">
                              <div className="profile" >
                                <ProfileImage name={`${selectedMember.firstName}`} />
                                <div>
                                  <h3 className="capitalize">{selectedMember.firstName}</h3>
                                  <div className="memberemail-image ">
                                    <img
                                      src={email}
                                      alt="email"
                                      className="email-image"

                                    />
                                    <p className="member-p-tag">{selectedMember.email}</p>
                                  </div>
                                </div>
                              </div>

                              <div className="member-meta">
                                <div
                                  className="mebermeta-divs"

                                >
                                  <img src={JoinDate} alt="joindate" />
                                  <ul className="dash-ul">
                                    <li>Join Date:</li>
                                    <li>
                                      {new Date(selectedMember.createdAt)
                                        .toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })
                                        .replace(/\//g, "-")}
                                    </li>
                                  </ul>
                                </div>
                                <div
                                  className="mebermeta-divs"


                                >
                                  <img src={plan} alt="plan" />
                                  <ul className="dash-ul">
                                    <li>Current Plan:</li>
                                    <li>{selectedMember.SubscriptionPlan}</li>
                                  </ul>
                                </div>
                                <div
                                  className="mebermeta-divs"

                                >
                                  <img src={status} alt="status" />
                                  <ul className="dash-ul">
                                    <li>Status:</li>
                                    <li>{selectedMember.status}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    sx={{ textAlign: "center", padding: "20px", fontSize: "20px", fontWeight: "bold" }}
                  >
                    No data found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={members.length > 0 ? totalCount : 0} 
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Box>

      {/* Add Note Modal */}

      <Dialog
        open={openNoteModal}
        onClose={handleCloseNoteModal}
        sx={{ "& .MuiDialog-paper": { width: "600px", maxWidth: "90%" } }}
      >
        <DialogTitle sx={{ textAlign: "center", fontSize: '25px', fontWeight: 700 }}>Notes</DialogTitle>  <DialogContent>
          <div className="note">
            <label className="note-label">Add your notes:</label>
            <textarea
              placeholder="Type Your Notes...."
              className="note-textarea"
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>

        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingBottom: "16px", gap: "20px" }}>

          <Button onClick={handleCloseNoteModal}
            sx={{
              backgroundColor: "white",
              color: "black",
              padding: "6px 8px",
              fontSize: "12px",
              border: "1px solid black",
              width: "120px",
              textAlign: "center",
            }}  >
            Cancel
          </Button>
          <Button onClick={handleSaveNote}
            sx={{
              backgroundColor: "black",
              color: "white",
              padding: "6px 8px",
              fontSize: "12px",
              width: "120px",
              textAlign: "center",

            }}>
            Save
          </Button>
        </DialogActions>



      </Dialog>

    </Box>
  );
};

export default MemberManagement;



